import React, { useRef, useEffect, useState, useCallback, useContext } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import '../layout/sass/_pickListSugestoes.scss'

import '../layout/sass/_responsiveTable.scss'
import { confirmDialog } from 'primereact/confirmdialog';
import api from '../api/api';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

export const TabelaInstalacao = () => {
    const history = useHistory();
    const toast = useRef(null);
    const { user } = useContext(AuthContext);
    const [instalacao, setInstalacao] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const confirmationToast = useRef(null)
    const [sortable, setSortable] = useState('ASC');
    const [first, setFirst] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [sortableCampo, setSortableCampo] = useState('id');
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 20,
        sortField: null,
        sortOrder: null,
        filters: {
            'id': { value: "", matchMode: 'contains' },
            'numeroInstalacao': { value: "", matchMode: 'contains' },
            'medidor': { value: "", matchMode: 'contains' },
            'endereco': { value: "", matchMode: 'contains' },
            'localidade': { value: "", matchMode: 'contains' },
        }
    });

    const header = (
        <div className="p-grid p-col-12 p-m-0 p-p-0">
            <div>
                <h2 className="p-my-0">Instalações</h2>
            </div>
            <div className="p-my-0 p-grid p-dir-rev p-col">
                <div className="p-m-2">
                    <Button
                        label="Adicionar"
                        className="p-mb-2 p-button-primary"
                        icon="pi pi-plus"
                        iconPos="left"
                        type="button"

                        onClick={() => history.push('/instalacaocreate')}

                    />
                </div>
            </div>
        </div>

    );

    const getInstalacao = useCallback(async (event) => {
        setIsLoading(true)
        setInstalacao([])

        if (event !== undefined) {
            setTimeout(() => {
                const startIndex = event.first;

                setFirst(startIndex);
            }, 250);
        }

        try {
            if (lazyParams.filters === undefined) {

                if (event === undefined) {
                    const response = await api.get(`instalacao?sort=${sortableCampo},${sortable}`);

                    response.data.content.forEach(instalacao => {
                        setInstalacao(instalacoes => [...instalacoes, instalacao])
                    })
                } else {
                    const response = await api.get(`instalacao?page=${event.page}&sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(instalacao => {
                        setInstalacao(instalacoes => [...instalacoes, instalacao])
                    })

                }

                const respElements = await api.get(`instalacao?sort=${sortableCampo},${sortable}`);
                setTotalRecords(respElements.data.totalElements);

            } else {
                const pesquisa = {
                    "id": lazyParams.filters['id'] !== undefined ? lazyParams.filters['id'].value : null,
                    "numeroInstalacao": lazyParams.filters['numeroInstalacao'] !== undefined ? lazyParams.filters['numeroInstalacao'].value : "",
                    "medidor": lazyParams.filters['medidor'] !== undefined ? lazyParams.filters['medidor'].value : "",
                    "endereco": lazyParams.filters['endereco'] !== undefined ? lazyParams.filters['endereco'].value : "",
                    "localidade": lazyParams.filters['localidade'] !== undefined ? lazyParams.filters['localidade'].value : ""
                }

                if (event === undefined) {
                    const respElements = await api.post(`instalacao/searchInstalacao/${sortable}/${sortableCampo}?`, pesquisa);
                    respElements.data.content.forEach(instalacao => {
                        setInstalacao(instalacoes => [...instalacoes, instalacao])
                    })
                    setTotalRecords(respElements.data.totalElements);
                } else {
                    const respElements = await api.post(`instalacao/searchInstalacao/${sortable}/${sortableCampo}?page=${event.page}`, pesquisa);
                    respElements.data.content.forEach(instalacao => {
                        setInstalacao(instalacoes => [...instalacoes, instalacao])
                    })
                    setTotalRecords(respElements.data.totalElements);
                }

            }

        }
        catch (error) {
            if (lazyParams !== undefined) {
                if (event === undefined) {
                    const response = await api.get(`instalacao?sort=id,${sortable}`);
                    response.data.content.forEach(instalacao => {
                        setInstalacao(instalacoes => [...instalacoes, instalacao])
                    })
                } else {
                    const response = await api.get(`instalacao?page=${event.page}&sort=id,${sortable}`);
                    response.data.content.forEach(instalacao => {
                        setInstalacao(instalacoes => [...instalacoes, instalacao])
                    })

                }

                const respElements = await api.get(`instalacao?sort=id,${sortable}`);
                setTotalRecords(respElements.data.totalElements);
            } else {
                toast.current.show({ severity: 'error', summary: 'Erro ao obter instalacao', detail: error.toString() });
            }
        }

        setIsLoading(false)
    }, [lazyParams, sortable, sortableCampo]);

    useEffect(() => {
        const fetchdotacaoOrcamentaria = async () => {
            try {
                await getInstalacao();

            }
            catch (error) {
                toast.current.show({ severity: 'error', summary: 'Erro ', detail: error.toString() });
            }
        }
        fetchdotacaoOrcamentaria();

    }, [getInstalacao])

    const idBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Código</span>
                    <span className="table-data">{rowData.id}</span>
                </p>
            </React.Fragment>
        );
    }

    const enderecoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Endereço</span>
                    <span className="table-data">{rowData.endereco}</span>
                </p>
            </React.Fragment>
        );
    }

    const numeroInstalacaoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Instalacao</span>
                    <span className="table-data">{rowData.numeroInstalacao}</span>
                </p>
            </React.Fragment>
        );
    }

    const medidorBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Medidor</span>
                    <span className="table-data">{rowData.medidor}</span>
                </p>
            </React.Fragment>
        );
    }

    const descricaoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Descrição</span>
                    <span className="table-data">{rowData.descricao}</span>
                </p>
            </React.Fragment>
        );
    }


    const localidadeBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <p style={{ textalign: 'left' }}>
                    <span className="p-column-title">Localidade</span>
                    <span className="table-data">{rowData.localidade}</span>
                </p>
            </React.Fragment>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-mr-2 edit-btn" onClick={() => handleEditClick(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-mr-2 p-button-danger" loading={isDeleting} onClick={() => handleDeleteClick(rowData)} />


            </div>
        );
    }

    const onSort = (event) => {
        setSortableCampo(event.sortField);
        if (sortable === 'ASC') {
            setSortable('DESC')
        } else if (sortable === 'DESC') {
            setSortable('ASC')
        }
    }

    const handleEditClick = (data) => {

        history.push(`/instalacao/edit/${data.id}`);

    }

    const handleDeleteClick = (data) => {

        data['userEmail'] = user != null ? user.email : ""

        confirmDialog({
            message: `Você tem certeza que quer remover a instalação?`,
            header: 'Confirmação',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            accept: async () => {
                setIsDeleting(true);

                try {

                    const response = await api.delete(`instalacao/${data.id}`);

                    toast.current.show({ life: 5000, severity: 'success', summary: 'Remoção concluída!', detail: `a instalação foi removida com sucesso` });
                    getInstalacao()
                }
                catch (error) {
                    toast.current.show({ life: 5000, severity: 'error', summary: 'Erro ao remover a instalação', detail: error.toString() });
                }

                setIsDeleting(false);
            },
            reject: () => { return }
        });
    }

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    }

    return (
        <>
            <div className="card p-grid p-col-12 p-mx-0 p-p-0 p-mt-0">

                <Toast ref={toast} position="bottom-right" />

                <Toast ref={confirmationToast} position="bottom-center" />
                <div className="card p-col-12 datatable-responsive-demo">

                    <div className="p-fluid">
                        <DataTable
                            totalRecords={totalRecords}
                            lazy="true"
                            first={first}
                            onPage={getInstalacao}
                            header={header}
                            value={instalacao}
                            filterDisplay="row"
                            className="p-datatable-responsive-demo"
                            emptyMessage="Nenhuma instalação encontrada"
                            loading={isLoading}
                            dataKey="id"
                            paginator
                            rows={20}
                            currentPageReportTemplate={`Mostrando de {first} a {last} de ${totalRecords} instalações`}
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                            // rowsPerPageOptions={[50, 100]}
                            onSort={onSort}
                            onFilter={onFilter}
                            filters={lazyParams.filters}
                        >

                            <Column
                                field="id"
                                header="Código"
                                sortable
                                body={idBodyTemplate}
                                lazyParams
                                filter
                                filterMatchMode="contains"
                                filterPlaceholder="Pesquisar por código"

                            />

                            <Column
                                field="numeroInstalacao"
                                header="Número de Instalação"
                                sortable
                                body={numeroInstalacaoBodyTemplate}
                                lazyParams
                                filter
                                filterMatchMode="contains"
                                filterPlaceholder="Pesquisar por instalação"
                            />

                            <Column
                                field="descricao"
                                header="descricao"
                                sortable
                                body={descricaoBodyTemplate}
                                lazyParams
                                filter
                                filterMatchMode="contains"
                                filterPlaceholder="Pesquisar por descricao"
                            />

                            <Column
                                field="medidor"
                                header="Medidor"
                                sortable
                                body={medidorBodyTemplate}
                                lazyParams
                                filter
                                filterMatchMode="contains"
                                filterPlaceholder="Pesquisar por medidor"
                            />

                            <Column
                                field="endereco"
                                header="Endereço"
                                sortable
                                body={enderecoBodyTemplate}
                                lazyParams
                                filter
                                filterMatchMode="contains"
                                filterPlaceholder="Pesquisar por endereço"

                            />

                            <Column
                                field="localidade"
                                header="Localidade"
                                sortable
                                body={localidadeBodyTemplate}
                                lazyParams
                                filter
                                filterMatchMode="contains"
                                filterPlaceholder="Pesquisar por localidade"

                            />

                            <Column
                                header="Ações"
                                body={actionBodyTemplate}

                            />

                        </DataTable>

                    </div>
                </div>
            </div>

        </>
    );
}
