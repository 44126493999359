import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Fieldset } from 'primereact/fieldset';
import { useParams } from "react-router-dom";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ErrorValidationMessage } from './ErrorValidationMessage';

import api from '../api/api';

import '../layout/sass/_form.scss';
import { InputNumber } from 'primereact/inputnumber';

export const FormEmpenhoItens = ({ isEditing, isLoading, setEmpenhoItens }) => {

    const { id } = useParams();
    const toast = useRef(null);
    const [isSubmiting, setIsSubmiting] = useState(false)
    const [listItens, setListItens] = useState(false);

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const schema = Yup.object().shape({
        itensId: Yup
            .number()
            .required("O item é obrigatória"),
        qtdeEmp: Yup
            .number()
            .required("A quantidade empenho é obrigatório"),
        valorUn: Yup
            .number()
            .required("A valor unitário é obrigatório"),
        
    })

   

    const formik = useFormik({

        initialValues: {
            "itensId": null,
            "qtdeEmp": 0,
            "qtdeLiq": 0,
            "valorUn": 0,
        },

        validationSchema: schema,

        enableReinitialize: false,

        onSubmit: async (data) => {
            setIsSubmiting(true)

            setEmpenhoItens(empenhoItens => [...empenhoItens, data]);
            setIsSubmiting(false)
        }
    })

    const handleGetItens = useCallback(async () => {

        await api.get("itens/itensAll").then((response) => {
            const itens = response.data.map(item => {
                return {
                    id: item.id,
                    descricaoEmpenho: item.descricaoEmpenho
                }
            });

            setListItens(itens);
        });

    }, [])

    useEffect(async () => {
        await handleGetItens();

    }, [handleGetItens]);


    return (

        <Fieldset legend="Itens" toggleable  >
            <div className="card p-grid p-col-12 p-mx-0 p-mt-0">
                <Toast ref={toast} position="bottom-right" />
                <div className="p-col-12">

                    <form onSubmit={formik.handleSubmit}>
                        <div className="p-formgrid p-grid p-fluid p-mx-0">

                            <div className="p-field p-col-12 p-sm-2">
                            <label
                                htmlFor="itensId"
                                className={classNames({ 'p-error': isFormFieldValid('itensId') })}
                            >
                                Itens
                            </label>
                            {

                                !isLoading ?
                                    <Dropdown
                                        id="itensId"
                                        name="itensId"
                                        options={listItens}
                                        filter={true}
                                        emptyMessage={"Nenhuma item encontrada"}
                                        optionLabel="descricaoEmpenho"
                                        optionValue="id"
                                        placeholder="Selecione um item"
                                        className={classNames({ 'p-invalid': isFormFieldValid('itensId') })}
                                        value={formik.values.itensId}
                                        onChange={option => formik.setFieldValue("itensId", option.value)}
                                        emptyFilterMessage={"Nenhuma item encontrada"}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.itensId && formik.touched.itensId &&
                                <ErrorValidationMessage message={formik.errors.itensId} />
                            }
                        </div>

                            

                            <div className="p-field p-col-12 p-sm-2">
                                <label
                                    htmlFor="qtdeEmp"
                                    className={classNames({ 'p-error': isFormFieldValid('qtdeEmp') })}
                                >
                                    Quantidade de Empenho
                                </label>
                                {
                                    !isLoading ?
                                        <InputText
                                            id="qtdeEmp"
                                            name="qtdeEmp"
                                            type="number"
                                            className={classNames({ 'p-invalid': isFormFieldValid('qtdeEmp') })}
                                            value={formik.values.qtdeEmp}
                                            onChange={formik.handleChange}
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.qtdeEmp && formik.touched.qtdeEmp &&
                                    <ErrorValidationMessage message={formik.errors.qtdeEmp} />
                                }
                            </div>



                            <div className="p-field p-col-12 p-sm-2">
                                <label
                                    htmlFor="qtdeLiq"
                                    className={classNames({ 'p-error': isFormFieldValid('qtdeLiq') })}
                                >
                                    Quantidade Liquidada
                                </label>
                                {
                                    !isLoading ?
                                        <InputText
                                            id="qtdeLiq"
                                            name="qtdeLiq"
                                            type="number"
                                            className={classNames({ 'p-invalid': isFormFieldValid('qtdeLiq') })}
                                            value={formik.values.qtdeLiq}
                                            onChange={formik.handleChange}
                                        />
                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.qtdeLiq && formik.touched.qtdeLiq &&
                                    <ErrorValidationMessage message={formik.errors.qtdeLiq} />
                                }
                            </div>


                            <div className="p-field p-col-12 p-sm-2">
                                <label
                                    htmlFor="valorUn"
                                    className={classNames({ 'p-error': isFormFieldValid('valorUn') })}
                                >
                                    Valor Unitário
                                </label>
                                {
                                    !isLoading ?
                                        <InputNumber 
                                            id="valorUn"
                                            name="valorUn"
                                            mode="currency" minFractionDigits={2} 
                                            currency="BRL"
                                            className={classNames({ 'p-invalid': isFormFieldValid('valorUn') })}
                                            locale="pt-BR"
                                            value={formik.values.valorUn}
                                            onChange={(e) => formik.setFieldValue("valor", e.valorUn)}  
                                        /> 
                                        :
                                        <Skeleton height="35px" />
                                }
                                {formik.errors.valorUn && formik.touched.valorUn &&
                                    <ErrorValidationMessage message={formik.errors.valorUn} />
                                }
                            </div>

                            

                            

                        

                            <div className="p-d-flex p-jc-end p-mr-1">

                                <Button
                                    icon="pi pi-plus"
                                    type="button"
                                    iconPos="right"
                                    onClick={formik.handleSubmit}
                                    loading={isSubmiting}
                                />
                            </div>

                            <div className="p-formgrid p-grid p-fluid p-mx-0">

                            </div>


                        </div>

                    </form>
                </div>
            </div>


        </Fieldset>

    );
}
