import React from 'react';
import MenuMain from '../../MenuMain';
import { FormSecretaria } from '../../components/FormSecretaria';
import { AppFooter } from '../../AppFooter';


export default function CadastrarSecretariaPage() {

  const defaultValues = {
    'codorg': '',
    'anoref': '',
    'codund': '',
    'nomund': ''

  }

  return (
    <div>
      <MenuMain>
         <FormSecretaria
          defaultValues={defaultValues}
        />
      </MenuMain>
      <AppFooter />
    </div>
  );
}

