import React, { useEffect, useState } from 'react';

import { FormEmpenho } from '../../components/FormEmpenho';
import { ErrorRetrievingData } from '../../components/ErrorRetrievingData';
import MenuMain from '../../MenuMain';

import { AppFooter } from '../../AppFooter';

import api from '../../api/api';
import { em } from '@fullcalendar/core/internal-common';

export default function EditarEmpenhoPage(props) {

    const [empenho, setEmpenho] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [errorGettingEmpenho, setErrorGettingEmpenho] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const [idEmpenho] = useState(props.match.params.id)

    const getEmpenho = async (id) => {
        setIsLoading(true);

        try {
            const response = await api.get(`empenho/${id}`)
            
            setEmpenho({
                'id': response.data.id,
                'secretariaId': response.data.secretariaId,
                'fornecedorId': response.data.fornecedorId,
                'ficha': response.data.ficha,    
                'fonte': response.data.fonte,      
                'numeroEmpenho': response.data.numeroEmpenho, 
                'dataEmissao': response.data.dataEmissao, 
                'dataVigencia': response.data.dataVigencia, 
                'valorEmpenho': response.data.valorEmpenho, 
                'valorLiquidado': response.data.valorLiquidado, 
            })

            setErrorGettingEmpenho(false)
        }
        catch (error) {
            setErrorMessage(error.toString())
            setErrorGettingEmpenho(true)
        }

        setIsLoading(false);
    }

    useEffect(() => {
        getEmpenho(idEmpenho)
    }, [])

    return (
        <>
            <MenuMain >
                {
                    errorGettingEmpenho ?
                        <ErrorRetrievingData
                            errorMessage={errorMessage}
                            retrieveFunction={() => getEmpenho(idEmpenho)}
                            isLoading={isLoading}
                        />
                        :
                        <FormEmpenho
                            defaultValues={empenho}
                            isEditing={true}
                            isLoading={isLoading}
                        />
                }
            </MenuMain>
            <AppFooter />
        </>
    );
}
