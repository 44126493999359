import React, { useEffect, useState } from 'react';

import { FormSecretaria } from '../../components/FormSecretaria';
import { ErrorRetrievingData } from '../../components/ErrorRetrievingData';
import MenuMain from '../../MenuMain';

import { AppFooter } from '../../AppFooter';

import api from '../../api/api';

export default function EditarSecretariaPage(props) {

    const [secretaria, setSecretaria] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [errorGettingSecretaria, setErrorGettingSecretaria] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const [idSecretaria] = useState(props.match.params.id)

    const getSecretaria = async (id) => {
        setIsLoading(true);

        try {
            const response = await api.get(`secretaria/${id}`)
            
            setSecretaria({
                'id': response.data.id,
                'nomund': response.data.nomund,          
                'codorg': response.data.codorg,
                'codund': response.data.codund,
                'anoref': response.data.anoref
            })

            setErrorGettingSecretaria(false)
        }
        catch (error) {
            setErrorMessage(error.toString())
            setErrorGettingSecretaria(true)
        }

        setIsLoading(false);
    }

    useEffect(() => {
        getSecretaria(idSecretaria)
    }, [])

    return (
        <>
            <MenuMain >
                {
                    errorGettingSecretaria ?
                        <ErrorRetrievingData
                            errorMessage={errorMessage}
                            retrieveFunction={() => getSecretaria(idSecretaria)}
                            isLoading={isLoading}
                        />
                        :
                        <FormSecretaria
                            defaultValues={secretaria}
                            isEditing={true}
                            isLoading={isLoading}
                        />
                }
            </MenuMain>
            <AppFooter />
        </>
    );
}
