import React from 'react';
import MenuMain from '../../MenuMain';
import { FormFornecedor } from '../../components/FormFornecedor';
import { AppFooter } from '../../AppFooter';


export default function CadastrarFornecedorPage() {

  const defaultValues = {
    'codigo': '',
    'nome': '',
    'telefone': '',
    'email': '',
    'contato': ''    
  }

  return (
    <div>
      <MenuMain>
         <FormFornecedor
          defaultValues={defaultValues}
        />
      </MenuMain>
      <AppFooter />
    </div>
  );
}

