import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { AppMenu } from './AppMenu';
import { AppProfile } from './AppProfile';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './layout/flags/flags.css';
import './layout/layout.scss';

import { AppTopbar } from './AppTopbar';

import Logo from './assets/logo.png';

const MenuMain = ({children}) => {

    const [layoutMode] = useState('static');
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const sidebar = useRef();

    const history = useHistory();

    let menuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, 'body-overflow-hidden');
        }
        else {
            removeClass(document.body, 'body-overflow-hidden');
        }
    }, [mobileMenuActive]);

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
        menuClick = false;
    }

    const onToggleMenu = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                setOverlayMenuActive(prevState => !prevState);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive(prevState => !prevState);
            }
        }
        else {
            setMobileMenuActive(prevState => !prevState);
        }
        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }

    const menu = [
        { label: 'Fornecedores', icon: 'pi pi-users', to: '/fornecedor' },
        { label: 'Secretarias', icon: 'pi pi-clone', to: '/secretaria' },
        // { label: 'Itens', icon: 'pi pi-copy', to: '/itens' },
        { label: 'Empenhos', icon: 'pi pi-th-large', to: '/empenho' },
        { label: 'Instalações', icon: 'pi pi-sitemap', to: '/instalacao' },
        { label: 'Faturas', icon: 'pi pi-credit-card', to: '/faturas' },
        { label: 'Liquidação', icon: 'pi pi-dollar', to: '/liquidacao' },
        { label: 'Logs', icon: 'pi pi-id-card', to: '/logs'},
    ];

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const isDesktop = () => {
        return window.innerWidth > 1024;
    }

    const isSidebarVisible = () => {
        if (isDesktop()) {
            if (layoutMode === 'static')
                return !staticMenuInactive;
            else if (layoutMode === 'overlay')
                return overlayMenuActive;
            else
                return true;
        }

        return true;
    }


    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
    });

    const sidebarClassName = classNames('layout-sidebar', 'layout-sidebar-light');

    return (
        <div className={wrapperClass} onClick={onWrapperClick}>
            <AppTopbar onToggleMenu={onToggleMenu} />
            <CSSTransition classNames="layout-sidebar" timeout={{ enter: 200, exit: 200 }} in={isSidebarVisible()} unmountOnExit>
                <div ref={sidebar} className={sidebarClassName} onClick={onSidebarClick}>
                    <div className="layout-Logo" style={{ cursor: 'pointer', padding: '10px 10px'}} onClick={() => history.push('/')}>
                        <img alt="Logo" src={Logo} width="100%" height="auto" style={{ maxWidth: '250px' }}/>
                    </div>
                    <AppProfile />
                    <AppMenu model={menu} onMenuItemClick={onMenuItemClick} />
                    
                </div>
                
            </CSSTransition>

            <div className="layout-main">
                <div className="p-col-12"  >                    
                    {children}
                </div>
            </div>

        </div>
    );

}

export default MenuMain;
