import React, { useEffect, useRef, useState } from 'react';

import { Toast } from 'primereact/toast';
import '../layout/sass/_responsiveTable.scss'
import { FileUpload } from 'primereact/fileupload';
import '../layout/sass/_dashboard.scss';
import api from '../api/api';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { ProgressBar } from 'primereact/progressbar';

export const FormUpload = ({ visible, setVisible, setLazyParams, Titulo, uploadTipo, listFornecedores }) => {
    const toast = useRef(null);
    const [disabled, setDisabled] = useState(false);
    const [style, setStyle] = useState({display: 'none'});
    const [arquivoNome, setArquivoNome] = useState("");
    const [selecionar, setSelecionar] = useState({ label: 'Selecionar', icon: 'pi pi-fw pi-plus' });
    const [enviar, setEnviar] = useState({ label: 'Enviar', icon: 'pi pi-upload' });
    const cancelar = { label: 'Cancelar', icon: 'pi pi-times' };
    const fileUploadRef = useRef(null);
    const [fornecedorSelecionado, setFornecedorSelecionado] = useState(null);

    const handleClear = () => {
        fileUploadRef.current.clear();
    };

    const handlerUpload = async (event) => {

        console.log(fornecedorSelecionado);

        for (var i = 0; i < event.files.length; i++) {
            setStyle({});
            let extensao = event.files[i].name;
            // console.log(extensao)
            const file = new File([event.files[i]], extensao);

            // console.log(file)
            const data = new FormData();

            data.append('file', file);
            data.append('Content-Type', 'multipart/form-data');
            data.append('fornecedorId', fornecedorSelecionado.id);
            try {
                if (uploadTipo == "0") {
                    // const response = await api.post(`/pdf/extrairEmpenhos`, data, { "Content-Type": "multipart/form-data" });
                    // handleClear();
                    // console.log(response.data)
                } else {
                    if (fornecedorSelecionado.cod === 'Algar') {
                        const response = await api.post(`/pdf/extrairAlgar`, data, { "Content-Type": "multipart/form-data" });
                        handleClear();
                        console.log(response.data)
                    }

                    if (fornecedorSelecionado.cod === 'Cemig') {
                        const response = await api.post(`/pdf/extrairCemig`, data, { "Content-Type": "multipart/form-data" });
                        handleClear();
                        console.log(response.data)
                    }


                    if (fornecedorSelecionado.cod === 8) {
                        const response = await api.post(`/pdf/extrairCopasa`, data, { "Content-Type": "multipart/form-data" });
                        handleClear();
                        console.log(response.data)
                    }

                }
            } catch (error) {
                toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao enviar empenho!', detail: error.toString() });

            }
            setStyle({display: 'none'});
            toast.current.show({ life: 2000, severity: 'success', summary: 'Arquivo enviado com sucesso!' });
            setTimeout(() => {
                setVisible(false)
                setLazyParams({
                    first: 0,
                    rows: 20,
                    sortField: null,
                    sortOrder: null,
                    filters: {}
                })
            }, 2000)



        }


    }

    useEffect(() => {
        if (disabled === true) {
            setSelecionar({ label: 'Selecionar', icon: 'pi pi-fw pi-plus', className: "p-button p-component p-disabled" })
        }
        else {
            setSelecionar({ label: 'Selecionar', icon: 'pi pi-fw pi-plus' })
        }
    }, [disabled, arquivoNome]);    

    return (
        <>
            <Dialog
                visible={visible}
                onHide={() => setVisible(false)}
                className='p-scrollpanel-bar-y'
                breakpoints={{ '960px': '75vw', '640px': '100vw' }}
                style={{ width: '50vw', heigth: '5vw' }}
            >
                <Toast ref={toast} position="bottom-right" />
                {
                    <>
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-md-12 p-xl-12" height="100" >
                                <div className="p-field p-col-12 p-sm-12">
                                    <label>
                                        {Titulo}
                                    </label>
                                    {uploadTipo == '1' ?
                                        <div className="card flex justify-content-center">
                                            <Dropdown value={fornecedorSelecionado} 
                                            onChange={(e) => setFornecedorSelecionado(e.value)} 
                                            options={listFornecedores} optionLabel="nome"
                                            placeholder="Selecione um Fornecedor" 
                                            className="w-full md:w-14rem" />
                                        </div>
                                        : <></>
                                    }

                                    {
                                        <>
                                            <FileUpload
                                                ref={fileUploadRef}
                                                customUpload
                                                accept=".txt, .csv"
                                                uploadHandler={handlerUpload}
                                                chooseOptions={selecionar}
                                                uploadOptions={enviar}
                                                cancelOptions={cancelar}
                                                onClear={() => setDisabled(false)}
                                                onRemove={() => setDisabled(false)}
                                                onSelect={(e) => { setDisabled(true); setArquivoNome(e.files[0].name) }}
                                                multiple
                                            />
                                            <ProgressBar mode="indeterminate" style={style}/>

                                        </>

                                    }
                                </div>
                            </div>
                        </div>
                    </>
                }
            </Dialog>
        </>
    );
}
