import React, { useRef, useEffect, useState } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { useHistory } from "react-router-dom";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';

import { PickList } from 'primereact/picklist';

import { Dropdown } from 'primereact/dropdown';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { ErrorValidationMessage } from './ErrorValidationMessage';

import api from '../api/api';

import '../layout/sass/_form.scss';

export const FormSecretaria = ({ isEditing, isLoading, defaultValues }) => {

    const [source, setSource] = useState([]);
    const [target, setTarget] = useState([]);


    const history = useHistory();
    const toast = useRef(null);
    const [isSubmiting, setIsSubmiting] = useState(false)

    // Obtém o ano atual
    const [anoAtual, setAnoAtual] = useState(new Date().getFullYear());
    const [listAnos, setListAnos] = useState(null);

    // const schema = Yup.object().shape({
    //     nome: Yup
    //         .string()
    //         .required("O nome é obrigatório")
    //         .max(200, "O nome dete ter no máximo 200 caracteres"),
    //     sigla: Yup
    //         .string()
    //         .required("O sigla é obrigatória")

    // })

    const handleChangeAno = async (data) => {        

        const response = await api.get(`/sigp/secretaria/ano/${data.target.value}`);

        setSource(response.data);

    }

    const onChange = (event) => {
        setSource(event.source);
        setTarget(event.target);
    };

    useEffect(async () => {

        // Cria a lista de anos dinamicamente
        setListAnos(
            Array.from({ length: 6 }, (_, index) => {
                const ano = anoAtual - 4 + index;
                return { label: ano, value: ano };
            }));

    }, []);

    const itemTemplate = (item) => {
        return (
            <div className="flex flex-wrap p-2 align-items-center gap-3">

                <div className="flex-1 flex flex-column gap-2">
                    <span className="font-bold">{item.nomund}</span>
                </div>

            </div>
        );
    };

    const formik = useFormik({

        initialValues: defaultValues,

        // validationSchema: schema,

        enableReinitialize: true,

        onSubmit: async (data) => {
            setIsSubmiting(true)

            try {
                if (!isEditing) {

                    await api.post("secretaria", target)
                    formik.resetForm()
                    toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro concluído!', detail: `${data.nome} adicionado(a) com sucesso` });
                    setTimeout(() => {
                        history.push('/secretaria')

                    }, 2000)

                }
                else {

                    await api.put("secretaria", target)

                    toast.current.show({ life: 2000, severity: 'success', summary: 'Cadastro atualizado!', detail: `${data.nome} atualizado(a) com sucesso` });
                    setTimeout(() => {

                        history.push('/secretaria')

                    }, 2000)
                }
            }
            catch (error) {
                if (!isEditing)
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao cadastrar secretaria!', detail: error.toString() });
                else
                    toast.current.show({ life: 2000, severity: 'error', summary: 'Erro ao editar secretaria!', detail: error.toString() });

            }

            setIsSubmiting(false)
        }
    })

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    return (
        <div className="card p-grid p-col-12 p-mx-0 p-mt-0">
            <Toast ref={toast} position="bottom-right" />
            <div className="p-col-12">
                <h2 className="p-mt-0">Secretaria</h2>
                <form onSubmit={formik.handleSubmit}>
                    <div className="p-formgrid p-grid p-fluid p-mx-0">
                        {/* Primeiro campo (Ano Ref.) */}
                        <div className="p-field p-col-12 p-sm-4">
                            <label
                                htmlFor="anoId"
                                className={classNames({ 'p-error': isFormFieldValid('anoId') })}
                            >
                                Ano

                            </label>
                            {

                                !isLoading ?
                                    <Dropdown
                                        id="anoId"
                                        name="anoId"
                                        options={listAnos}
                                        filter={true}
                                        emptyMessage={"Nenhum ano encontrado"}
                                        optionLabel="label"
                                        optionValue="value"
                                        placeholder="Selecione um ano"
                                        className={classNames({ 'p-invalid': isFormFieldValid('anoId') })}
                                        value={anoAtual}
                                        onChange={handleChangeAno}                                        
                                        emptyFilterMessage={"Nenhum fornecedor encontrada"}

                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.anoId && formik.touched.anoId &&
                                <ErrorValidationMessage message={formik.errors.anoId} />
                            }
                        </div>

                        {/* <div className="p-field p-col-12">
                            <label
                                htmlFor="anoref"
                                className={classNames({ 'p-error': isFormFieldValid('anoref') })}
                            >
                                Ano Ref.
                            </label>
                            {
                                !isLoading ?
                                    <InputText
                                        id="anoref"
                                        name="anoref"
                                        className={classNames({ 'p-invalid': isFormFieldValid('anoref') })}
                                        value={formik.values.anoref}
                                        onChange={formik.handleChange}
                                        onBlur={handleChangeAno}
                                    />
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.anoref && formik.touched.anoref &&
                                <ErrorValidationMessage message={formik.errors.anoref} />
                            }
                        </div> */}

                        {/* Segundo campo (PickList) */}
                        <div className="p-field p-col-12">
                            <label
                                htmlFor="nome"
                                className={classNames({ 'p-error': isFormFieldValid('nome') })}
                            >
                                Nome
                            </label>
                            {
                                !isLoading ?
                                    <div className="card" style={{ width: '80%' }}> {/* Ajustando a largura do PickList */}
                                        <PickList
                                            dataKey="id"
                                            source={source}
                                            target={target}
                                            onChange={onChange}
                                            itemTemplate={itemTemplate}
                                            breakpoint="1280px"
                                            sourceHeader="Secretarias"
                                            targetHeader="Selecionadas"
                                            sourceStyle={{ height: '48rem' }}
                                            targetStyle={{ height: '48rem' }}
                                        />
                                    </div>
                                    :
                                    <Skeleton height="35px" />
                            }
                            {formik.errors.nome && formik.touched.nome &&
                                <ErrorValidationMessage message={formik.errors.nome} />
                            }
                        </div>
                    </div>

                    <div className="p-d-flex p-jc-end p-mr-1">
                        {
                            !isLoading ?
                                <Button
                                    label={isEditing ? 'Atualizar' : 'Importar'}
                                    type="submit"
                                    iconPos="right"
                                    loading={isSubmiting}
                                />
                                :
                                <Skeleton width="82.16px" height="35px" />
                        }
                    </div>
                </form>
            </div>
        </div>

    );
}
