import React from 'react';
import MenuMain from '../../MenuMain';
import { FormEmpenho } from '../../components/FormEmpenho';
import { AppFooter } from '../../AppFooter';


export default function CadastrarEmpenhoPage() {

  const defaultValues = {
    'secretariaId': '',
    'fornecedorId': '',
    'ficha': '',
    'fonte': '',
    'numeroEmpenho': '',
    'dataEmissao': '',
    'dataVigencia': '',
    'valorEmpenho': 0,
    'valorLiquidado': 0,
  }

  return (
    <div>
      <MenuMain>
         <FormEmpenho
          defaultValues={defaultValues}
        />
      </MenuMain>
      <AppFooter />
    </div>
  );
}

